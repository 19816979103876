<template>
    <div id="sc-tickets">
        <div id="sc-header">
            <h3>Retail</h3>

            <div id="sc-reload" @click="getRetailTickets()">
                <b-icon icon="arrow-clockwise" style="width: 40px; height: 40px;"></b-icon>
            </div>
        </div>

        <hr />

        <!-- Loading Spinner -->
        <div v-if="ticketsList.length == 0" class="sc-loading-spinner">
            <b-spinner variant="primary" label=""></b-spinner>
        </div>

        <!-- Table -->
        <v-client-table v-else :data="ticketsList" :columns="columns" :options="options">
            <div class="sc-actions-container" slot="actions" slot-scope="{ row }">
                <b-icon-pencil-square class="sc-icon-edit" @click="onClickEditTicket(row.id)"></b-icon-pencil-square>
                <b-icon-trash class="sc-icon-delete" @click="onClickDeleteTicket(row.id)"></b-icon-trash>
                <b-form-checkbox size="lg" v-show="!row.quickbooksInvoice" @change="onChangeCheckbox($event, row)"></b-form-checkbox>
            </div>

            <!-- Print -->
            <div class="sc-print-container" slot="print" slot-scope="{ row }">
                <b-button class="sc-button-primary" @click="onClickPrintInvoice(row)">Print</b-button>
            </div>
        </v-client-table>

        <!-- Modal - Print Ticket -->
        <b-modal id="sc-modal-print-ticket" ref="sc-modal-print-ticket" size="md" title="Print Ticket" centered hide-footer>
            <div v-if="printTicketRow != null" id="sc-print-ticket-container">
                <div style="width: 250px; display: flex; flex-direction: column; align-items: center; font-family: Calibri">
                    <img style="width: 100px; object-fit: contain; margin-top: 20px;" src="./../../assets/logo.png" />
                    <h4 style="margin-top: 20px;">SaltCheap</h4>
                    <span>{{ printTicketRow.customer.company }}</span>
                    <span>Ticket # {{ printTicketRow.id }}</span>
                    <span>{{ printTicketCreatedAt }}</span>
                    <hr />

                    <table border="2" style="width: 100%; border-collapse: collapse;  margin-top: 20px">
                        <tr>
                            <th>Item</th>
                            <th>QTY</th>
                            <th>Price</th>
                        </tr>

                        <tr v-for="lineitem in printTicketRow.lineitems" :key="lineitem.id">
                            <td style="font-size: 14px">{{ lineitem.item.name }}</td>
                            <td>{{ lineitem.quantity }}</td>
                            <td style="font-size: 14px">$ {{ parseFloat(lineitem.item.price * lineitem.quantity).toFixed(2) }}</td>
                        </tr>
                    </table>

                    <div style="width: 70%; font-weight: bold; font-size: 14px; margin-top: 40px;">
                        <div style="display: flex; justify-content: space-between">
                            <div>Sub total :</div>
                            <div>$ {{ parseFloat(printTicketSubtotal).toFixed(2) }}</div>
                        </div>
                        <div style="display: flex; justify-content: space-between">
                            <div>HST (13%) :</div>
                            <div>$ {{ parseFloat(printTicketSubtotal * 0.13).toFixed(2) }}</div>
                        </div>
                        <div style="display: flex; justify-content: space-between">
                            <div>Total :</div>
                            <div>$ {{ parseFloat(printTicketSubtotal + printTicketSubtotal * 0.13).toFixed(2) }}</div>
                        </div>
                    </div>
                    <hr />
                    <hr />

                    <div style="display: flex; justify-content: space-between; font-size: 12px; margin-top: 40px;">
                        <div>HST</div>
                        <div>: {{ printHSTNumber }}</div>
                    </div>
                    <hr />
                    <hr />
                </div>
            </div>
            <b-button class="mt-4 sc-button-primary" block @click="printTicket">Print</b-button>
        </b-modal>
    </div>
</template>

<script>
import _ from "lodash";
import APICalls from "./APICalls";
import util from "./../../util/util";

export default {
    name: "Retails",
    data() {
        return {
            columns: ["id", "customer.company", "products", "linteItemsSku", "truck", "dateTime", "linteItemsQuantity", "quickbooksInvoice", "print"],
            printTicketRow: null,
            printTicketCreatedAt: null,
            printTicketSubtotal: 0,
            printTicketTotal: 0,
            printHSTNumber: "849317375RT0001",
            ticketsList: [
                // { id: 1345, company: "Test 12", name: "John", email: "ank@gmail.com", phone: "2043443434" },
            ],
            invoiceTicketsList: [],
            options: {
                perPage: 200,
                columnsDropdown: false,
                orderBy: {
                    column: "id",
                    ascending: false,
                },
                headings: {
                    id: "#",
                    "customer.company": "Customer",
                    linteItemsSku: "SKU",
                    linteItemsQuantity: "Quantity",
                    dateTime: "Date",
                    quickbooksInvoice: "QB #",
                },
                templates: {
                    linteItemsSku(h, ticket) {
                        let res = "";
                        _.each(ticket.lineitems, (lineitem) => {
                            res += lineitem.item.sku + "\n";
                        });
                        return res;
                    },
                    linteItemsQuantity(h, ticket) {
                        let res = "";
                        _.each(ticket.lineitems, (lineitem) => {
                            res += lineitem.quantity + "\n";
                        });
                        return res;
                    },
                    carryOver(h, ticket) {
                        return ticket.customer.carryOver;
                    },
                    dateTime(h, ticket) {
                        return util.formatTimestamp(ticket.createdAt);
                    },
                },
            },
        };
    },
    methods: {
        onClickPrintInvoice(row) {
            this.printTicketRow = row;
            this.$refs["sc-modal-print-ticket"].show();
        },
        async printTicket() {
            this.$htmlToPaper("sc-print-ticket-container");
        },
        async getRetailTickets() {
            let tickets = await APICalls.getTicketsForCustomer(151); // 151 is Retail Walkin customer
            this.ticketsList = tickets;
        },
    },
    watch: {
        printTicketRow: function(ticket) {
            if (ticket == null) return;

            this.printTicketSubtotal = 0.0;
            this.printTicketTotal = 0.0;
            this.printTicketCreatedAt = util.formatTimestamp(ticket.createdAt);

            _.each(ticket.lineitems, (lineitem) => {
                this.printTicketSubtotal += parseFloat(lineitem.item.price) * parseFloat(lineitem.quantity);
            });
        },
        ticketsList: function(val) {
            _.each(val, (v) => {
                let products = "";
                _.each(v.lineitems, (obj) => {
                    products += obj.item.name + "\n";
                });

                v.products = products;
            });
        },
    },
    mounted() {
        this.getRetailTickets();
    },
};
</script>

<style lang="scss">
#sc-tickets {
    #sc-header {
        background: transparent;
        box-shadow: none;
        display: flex;

        #sc-reload {
            cursor: pointer;
        }
    }

    .sc-actions-container {
        display: flex;
        align-items: center;

        .sc-icon-edit {
            width: 22px;
            height: 22px;
            margin-right: 20px;
            color: black;
            cursor: pointer;
        }

        .sc-icon-delete {
            width: 22px;
            height: 22px;
            margin-right: 20px;
            color: black;
            cursor: pointer;
        }

        .sc-button-primary {
            margin-top: 10px;
            width: 160px;
        }
    }

    .VueTables {
        .row {
            // Columns - Dropdown
            .dropdown {
                .dropdown-toggle {
                    background-color: #007bc4 !important;
                    height: 45px;
                }

                .dropdown-menu {
                    padding: 10px;
                    font-size: 18px;

                    input {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    table {
        tbody {
            tr {
                td {
                    white-space: pre;
                    word-wrap: break-word;
                }
                td:nth-child(2) {
                    font-size: 14px;
                }
                td:nth-child(4) {
                    font-size: 14px;
                }
                td:nth-child(5) {
                    max-width: 120px;
                    word-wrap: break-word;
                    overflow-wrap: break-word !important;
                    white-space: normal;
                }
                td:nth-child(6) {
                    font-size: 14px;
                }
            }
        }
    }
}

#sc-modal-print-ticket {
    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
</style>
